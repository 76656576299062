/* Main container */
.mainContainer {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 2.5rem 3.5rem 2.5rem 4.5rem; /* Increased left padding from 2rem to 4.5rem */
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Content row - circle and header */
.contentRow {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center; /* Added to center content with slight right bias */
}

.circleColumn {
  flex: 0 0 auto;
  margin-right: 3.5rem;
  transform: translateX(0); /* Changed from -20px to 0 */
  opacity: 0;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.circleColumn.animate {
  transform: translateX(0);
  opacity: 1;
}

.headerColumn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(20px);
  opacity: 0;
  transition: transform 0.8s ease-out 0.2s, opacity 0.8s ease-out 0.2s;
}

.headerColumn.animate {
  transform: translateX(0);
  opacity: 1;
}

.headerWrap {
  display: flex;
  flex-direction: column;
}

/* Enhanced Impact Score Header */
.impactScoreHeader {
  font-size: 4.5rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
  letter-spacing: 1.5px;
  line-height: 1.1;
}

.impactWord {
  display: inline-block;
  margin-right: 0.5rem;
  background: linear-gradient(90deg, #38B2AC 0%, #319795 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.scoreWord {
  display: inline-block;
  background: linear-gradient(90deg, #319795 0%, #2C7A7B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.headerUnderline {
  height: 4px;
  width: 120px;
  background: linear-gradient(90deg, #38B2AC 0%, #2C7A7B 100%);
  border-radius: 2px;
  margin-top: 10px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s ease-out 0.5s;
}

.headerColumn.animate .headerUnderline {
  transform: scaleX(1);
}

/* Button row */
.buttonRow {
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.6s ease-out 0.4s, opacity 0.6s ease-out 0.4s;
  margin-right: -2rem; /* Added to keep button aligned with the balanced content above */
}

.buttonRow.animate {
  transform: translateY(0);
  opacity: 1;
}

/* Button styling */
.discoverButton {
  display: inline-block;
  padding: 14px 34px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 2px solid #319795;
  color: #319795;
  background-color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  min-width: 280px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.discoverButton:hover {
  background-color: #319795;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.discoverButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 151, 149, 0.3);
}

.discoverButton:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Circular progress bar styling */
.circularProgressBar {
  position: relative;
  width: 300px;
  height: 300px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;
}

.circularProgressBar:hover {
  transform: scale(1.02);
  filter: drop-shadow(0 6px 10px rgba(0, 0, 0, 0.15));
}

.backgroundCircle {
  transition: stroke 0.5s ease;
}

.progressCircle {
  /* Transform is now handled inline in the SVG for better control */
  transition: stroke-dashoffset 1.2s ease-out;
}

.scoreContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.scoreValueWrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.scoreValueWrapper:hover {
  transform: scale(1.05);
}

.scoreValue {
  font-size: 4rem;
  font-weight: 700;
  color: #4A5568;
  line-height: 1.2;
}

.scoreChange {
  position: absolute;
  top: 0;
  right: -20px;
  font-size: 1.25rem;
}

.scoreChangeIcon {
  color: #48BB78;
}

.tierName {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0.25rem;
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.circleTooltip {
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%) translateY(5px);
}

.scoreTooltip {
  top: -40px;
  left: 50%;
  transform: translateX(-50%) translateY(-5px);
}

.circularProgressBar:hover .circleTooltip {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.scoreValueWrapper:hover + .scoreTooltip {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .impactScoreHeader {
    font-size: 4rem;
  }
  
  .circularProgressBar {
    width: 280px;
    height: 280px;
  }
  
  .mainContainer {
    padding: 2.5rem 2.5rem 2.5rem 3.5rem; /* Adjusted for tablet view */
  }
}

@media (max-width: 768px) {
  .contentRow {
    flex-direction: column;
    text-align: center;
  }
  
  .circleColumn {
    margin-right: 0;
    margin-bottom: 2rem;
    transform: translateY(-20px);
  }
  
  .circleColumn.animate {
    transform: translateY(0);
  }
  
  .headerColumn {
    justify-content: center;
    transform: translateY(20px);
  }
  
  .headerColumn.animate {
    transform: translateY(0);
  }
  
  .headerWrap {
    align-items: center;
  }
  
  .impactScoreHeader {
    font-size: 3.5rem;
    text-align: center;
  }
  
  .headerUnderline {
    margin: 10px auto 0;
    transform-origin: center;
  }
  
  .circularProgressBar {
    width: 260px;
    height: 260px;
  }
  
  .scoreValue {
    font-size: 3.5rem;
  }
  
  .mainContainer {
    padding: 2rem 1.5rem; /* Reset for mobile view */
  }
  
  .buttonRow {
    margin-right: 0; /* Reset for mobile */
  }
}

@media (max-width: 480px) {
  .mainContainer {
    padding: 2rem 1rem;
  }
  
  .impactScoreHeader {
    font-size: 2.8rem;
  }
  
  .circularProgressBar {
    width: 220px;
    height: 220px;
  }
  
  .scoreValue {
    font-size: 3rem;
  }
  
  .discoverButton {
    min-width: 240px;
    padding: 12px 24px;
    font-size: 16px;
  }
}