/* Import clean design variables */
:root {
  --primary-color: #2d8f7b;
  --primary-light: #5ecfb6;
  --primary-dark: #236e61;
  --text-primary: #212121;
  --text-secondary: #757575;
  --background-light: #f8f8f8;
  --border-light: #e0e0e0;
  --shadow-sm: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 6px 12px rgba(0, 0, 0, 0.15);
  --transition-standard: all 0.3s ease;
}

.modalContent {
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.modalContent:hover {
  box-shadow: var(--shadow-md);
}

.modalContent h2 {
  font-size: 22px;
  font-weight: 700;
  color: var(--primary-dark);
  margin-bottom: 15px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 10px;
}

.titleIcon {
  font-size: 24px;
  color: var(--primary-color);
}

.scoreIcon {
  font-size: 18px;
  color: var(--primary-color);
  margin-right: 8px;
}

.tierProgress {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  width: 100%;
}

.tierBarContainer {
  background-color: var(--background-light);
  border-radius: 8px;
  height: 52px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-light);
  transition: var(--transition-standard);
  width: 100%;
}

.tierBarContainer:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

.tierBar {
  display: flex;
  align-items: center;
  height: 100%;
  transition: width 0.5s ease-in-out;
  width: 100%;
}

.tierIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 8px;
  font-size: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: var(--transition-standard);
  padding: 8px;
}

.tierBarContainer:hover .tierIcon {
  transform: scale(1.1);
}

.achieved .tierIcon {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.tierName {
  margin-left: 12px;
  font-weight: 600;
  color: var(--text-primary);
  font-size: 16px;
  flex: 1;
}

.tierScore {
  margin-right: 16px;
  font-weight: 500;
  color: var(--text-secondary);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
}

.achieved {
  color: white;
}

.achieved .tierScore {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

.currentScore, .nextTier {
  margin-top: 20px;
  font-size: 16px;
  color: var(--text-primary);
  line-height: 1.6;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.nextTier {
  font-weight: 600;
  color: var(--primary-color);
}

/* Tier-specific colors */
.tierBar[data-tier="Visionary"] {
  background: linear-gradient(45deg, #FFD700, #FFA500);
}

.tierBar[data-tier="Champion"] {
  background: linear-gradient(45deg, #C0C0C0, #A9A9A9);
}

.tierBar[data-tier="Philanthropist"] {
  background: linear-gradient(45deg, #CD7F32, #8B4513);
}

.tierBar[data-tier="Altruist"] {
  background: linear-gradient(45deg, #2ECC71, #27AE60);
}

.tierBar[data-tier="Giver"] {
  background: linear-gradient(45deg, #E74C3C, #C0392B);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modalContent {
    padding: 20px;
    width: 95%;
  }

  .modalContent h2 {
    font-size: 20px;
  }

  .titleIcon {
    font-size: 20px;
  }

  .scoreIcon {
    font-size: 16px;
  }

  .tierBarContainer {
    height: 48px;
  }

  .tierIcon {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  .tierName {
    font-size: 14px;
  }

  .tierScore {
    font-size: 12px;
    padding: 3px 10px;
  }

  .currentScore, .nextTier {
    font-size: 14px;
  }
}