:root {
  --teal: #90C49F;
  --teal-light: #A8D4B4;
  --text-dark: #2D3748;
  --text-gray: #4A5568;
  --background: #FAFAFA;
  --border-color: rgba(144, 196, 159, 0.2);
  --card-background: #FFFFFF;
}

.welcomePage {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  color: var(--text-dark);
  background-color: var(--background);
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 60px;
}

.featuresContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 0;
  margin: 80px 0;
}

.feature {
  background-color: var(--card-background);
  padding: 64px 48px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.featureIcon {
  margin-bottom: 40px;
  color: var(--teal);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroicon {
  width: 100%;
  height: 100%;
  color: inherit;
  stroke-width: 1.5;
}

.featureTitle {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--text-dark);
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature p {
  font-size: 16px;
  margin-bottom: 40px;
  color: var(--text-gray);
  line-height: 1.6;
  text-align: center;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(144, 196, 159, 0.15), rgba(123, 171, 138, 0.2));
}

.learnMoreButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  background-color: transparent;
  color: var(--teal);
  text-decoration: none;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid var(--border-color);
  margin-top: auto;
  height: 34px;
  min-width: 120px;
  transition: all 0.2s ease;
  align-self: center;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.2px;
}

.learnMoreButton:hover {
  background-color: var(--teal);
  color: white;
  border-color: var(--teal);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  background-color: var(--card-background);
  border-bottom: 1px solid var(--border-color);
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  width: auto;
}

.headerCta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  background-color: transparent;
  color: var(--teal);
  text-decoration: none;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid var(--border-color);
  height: 34px;
  min-width: 90px;
  transition: all 0.2s ease;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.2px;
}

.headerCta:hover {
  background-color: var(--teal);
  color: white;
  border-color: var(--teal);
}

.hero {
  position: relative;
  text-align: center;
  color: white;
  padding: 120px 20px;
}

.heroImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroContent {
  position: relative;
  z-index: 1;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--card-background);
  padding: 56px;
  border-radius: 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.heroTitle {
  font-size: 42px;
  margin-bottom: 24px;
  color: var(--text-dark);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.highlight {
  color: var(--teal);
  font-weight: 700;
}

.heroSubtitle {
  font-size: 18px;
  margin-bottom: 40px;
  color: var(--text-gray);
  line-height: 1.6;
  text-align: center;
}

.ctaButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  background-color: var(--teal);
  color: white;
  text-decoration: none;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  height: 40px;
  min-width: 140px;
  transition: background-color 0.2s ease;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.2px;
}

.ctaButton:hover {
  background-color: var(--teal-light);
}

.footer {
  background-color: var(--teal);
  color: white;
  text-align: center;
  padding: 48px 20px;
  margin-top: 80px;
}

.footerLinks {
  margin-top: 24px;
}

.footerLinks a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  margin: 0 16px;
  transition: color 0.2s ease;
  font-size: 14px;
}

.footerLinks a:hover {
  color: white;
}

.socialIcons {
  margin-top: 28px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.socialIcon {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  text-decoration: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.socialIcon:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}

@media (max-width: 768px) {
  .welcomePage {
    padding: 40px 20px;
  }

  .header {
    padding: 20px;
  }

  .featuresContainer {
    grid-template-columns: 1fr;
    gap: 32px;
    margin: 40px 0;
  }

  .feature {
    padding: 40px 24px;
  }

  .heroContent {
    padding: 32px;
    margin: 0 20px;
  }

  .heroTitle {
    font-size: 32px;
  }

  .heroSubtitle {
    font-size: 16px;
  }

  .ctaButton {
    padding: 0 24px;
    height: 36px;
    min-width: 120px;
  }

  .featureIcon {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
  }

  .featureTitle {
    font-size: 20px;
  }

  .feature p {
    font-size: 15px;
    margin-bottom: 32px;
  }

  .learnMoreButton {
    padding: 0 20px;
    height: 32px;
    min-width: 100px;
  }
}