/* BusinessDashboard.module.css */

.dashboardContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.dashboardTitle {
  font-size: 32px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 30px;
  text-align: center;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.businessOverview,
.financialSummary,
.createdCampaigns {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 30px;
  margin-bottom: 30px;
}

.createCampaignContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.createCampaignButton {
  display: inline-block;
  padding: 12px 24px;
  background: var(--primary-gradient);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 600;
  transition: var(--transition-standard);
}

.createCampaignButton:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
}

.categoryCard {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 20px;
}

.categoryTitle {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 15px;
}

.summaryCard {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 10px;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  margin-bottom: 10px;
}

.campaignName {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.campaignsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.campaignCard {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 20px;
}

.campaignTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.noCampaigns {
  text-align: center;
  color: var(--text-secondary);
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}

.loadingContainer,
.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: var(--text-primary);
}

.errorMessage {
  color: #dc2626;
  text-align: center;
}

@media (max-width: 768px) {
  .dashboardTitle {
    font-size: 28px;
  }

  .sectionTitle {
    font-size: 22px;
  }

  .categoryCard {
    padding: 15px;
  }

  .cardTitle {
    font-size: 16px;
  }

  .campaignTitle {
    font-size: 16px;
  }
}