.yourImpactContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scoreCalculationInfo {
  margin-top: 30px;
}

.scoreCalculationInfo h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.categoryInfo {
  margin-bottom: 20px;
}

.categoryInfo h3 {
  font-size: 20px;
  color: #4CAF50;
  margin-bottom: 10px;
}

.categoryInfo ul {
  list-style-type: none;
  padding-left: 0;
}

.categoryInfo li {
  margin-bottom: 5px;
  font-size: 16px;
  color: #555;
}

.totalScoreInfo {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}