.layoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
}

.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #4CAF50;
}

.navLinks {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.navLinks li {
  margin: 0;
  padding: 0;
}

.navLink {
  color: #4CAF50;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.navLink:hover,
.navLink:focus,
.navLink:active,
.active {
  color: #3a7e3a;
  text-decoration: none;
}

.logoutButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: #3a7e3a;
}

.content {
  flex-grow: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 768px) {
  .navbarContent {
    flex-wrap: wrap;
  }

  .hamburger {
    display: block;
  }

  .navLinks {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
  }

  .navLinks.expanded {
    display: flex;
  }

  .navLink {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  .logoutButton {
    margin: 0.5rem 0;
  }
}

/* Remove all !important declarations */
.navLink,
.active,
.navLinks,
.navLinks li,
.navLinks li a {
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}