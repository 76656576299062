/* SignUp.module.css */
.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--background-light);
}

.signupContainer {
  width: 100%;
  max-width: 400px;
  padding: 48px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  position: relative;
  overflow: hidden;
}

.signupContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--primary-gradient);
}

.logo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 40px;
  object-fit: contain;
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 40px;
}

form {
  width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.formGroup {
  margin-bottom: 24px;
  width: 320px;
  box-sizing: border-box;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 15px;
}

.formGroup input {
  width: 320px;
  height: 48px;
  padding: 0 16px;
  border: 2px solid var(--border-light);
  border-radius: 12px;
  font-size: 15px;
  color: var(--text-primary);
  background-color: white;
  transition: var(--transition-standard);
  box-sizing: border-box;
}

.formGroup input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(45, 143, 123, 0.1);
}

.formGroup input.error {
  border-color: #dc2626;
}

.passwordWrapper {
  position: relative;
  width: 320px;
  box-sizing: border-box;
}

.toggleButton {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: var(--transition-standard);
}

.toggleButton:hover {
  color: var(--primary-color);
}

.passwordStrength {
  margin-top: 8px;
  height: 4px;
  background-color: var(--border-light);
  border-radius: 2px;
  overflow: hidden;
  width: 320px;
  box-sizing: border-box;
}

.passwordStrengthBar {
  height: 100%;
  transition: var(--transition-standard);
}

.passwordStrengthBar.weak {
  width: 25%;
  background-color: #dc2626;
}

.passwordStrengthBar.medium {
  width: 50%;
  background-color: #f59e0b;
}

.passwordStrengthBar.strong {
  width: 75%;
  background-color: #10b981;
}

.passwordStrengthBar.veryStrong {
  width: 100%;
  background-color: #059669;
}

.passwordStrengthText {
  font-size: 13px;
  color: var(--text-secondary);
  text-align: right;
  margin-top: 4px;
  width: 320px;
  box-sizing: border-box;
}

.submitButton {
  width: 320px;
  height: 48px;
  margin-top: 16px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-standard);
  box-sizing: border-box;
}

.submitButton:hover {
  background: var(--primary-dark);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.submitButton:active {
  transform: translateY(0);
}

.submitButton:disabled {
  background: #a0a0a0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.socialLogin {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid var(--border-light);
  text-align: center;
}

.socialLogin h3 {
  color: var(--text-secondary);
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.socialButtons {
  display: grid;
  gap: 12px;
  width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.socialButton {
  width: 320px;
  height: 48px;
  border: 2px solid var(--border-light);
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  background: white;
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: var(--transition-standard);
  box-sizing: border-box;
}

.socialButton svg {
  width: 20px;
  height: 20px;
}

.socialButton.google:hover {
  border-color: #db4437;
  background-color: rgba(219, 68, 55, 0.05);
}

.socialButton.microsoft:hover {
  border-color: #00a4ef;
  background-color: rgba(0, 164, 239, 0.05);
}

.error,
.success {
  padding: 14px 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.error {
  background-color: #fef2f2;
  border-left: 4px solid #dc2626;
  color: #dc2626;
}

.success {
  background-color: #ecfdf5;
  border-left: 4px solid #059669;
  color: #059669;
}

.error svg,
.success svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.validationMessage {
  font-size: 13px;
  margin-top: 4px;
  display: block;
  width: 320px;
  box-sizing: border-box;
}

.validationMessage.error {
  color: #dc2626;
  background: none;
  border: none;
  padding: 0;
  margin: 4px 0 0;
}

.toggleText {
  text-align: center;
  margin-top: 24px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.toggleText a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: var(--transition-standard);
}

.toggleText a:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

@media (max-width: 480px) {
  .signupContainer {
    padding: 32px 24px;
  }

  .title {
    font-size: 28px;
  }

  .formGroup input,
  .submitButton,
  .socialButton {
    height: 46px;
    font-size: 14px;
  }

  .logo {
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
  }
}