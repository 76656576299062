/* CharitySignup.module.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.card {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 30px;
  margin-top: 20px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 20px;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--text-secondary);
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-light);
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.input:focus,
.textarea:focus,
.select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
}

.button {
  background: var(--primary-gradient);
  color: white;
  border: none;
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  margin-top: 20px;
}

.button:hover {
  opacity: 0.9;
}

.error {
  color: #ff4d4f;
  margin-bottom: 15px;
}

.success {
  color: #52c41a;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .card {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }

  .input,
  .textarea,
  .select,
  .button {
    font-size: 14px;
  }
}