.adminDashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: var(--background-light);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
  background: white;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-light);
}

.title {
  font-size: 28px;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-weight: bold;
}

.nav {
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 20px;
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.nav:hover {
  box-shadow: var(--shadow-md);
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.navItem {
  margin: 5px;
}

.navLink {
  text-decoration: none;
  color: var(--text-primary);
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 50px;
  transition: var(--transition-standard);
  border: 2px solid transparent;
}

.navLink:hover {
  background-color: var(--primary-light);
  color: white;
  border-color: var(--primary-color);
}

.logoutButton {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 4px 2px;
  transition: var(--transition-standard);
  cursor: pointer;
  border-radius: 50px;
}

.logoutButton:hover {
  background-color: var(--primary-color);
  color: white;
}

.content {
  margin-top: 20px;
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.content:hover {
  box-shadow: var(--shadow-md);
}

@media (max-width: 768px) {
  .navList {
    flex-direction: column;
    align-items: center;
  }

  .navItem {
    width: 100%;
    text-align: center;
  }

  .navLink {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .header {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .title {
    font-size: 24px;
  }

  .logoutButton {
    width: 100%;
  }
}