.container {
  margin-bottom: 40px;
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  isolation: isolate;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 3;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #2d8f7b;
  display: flex;
  align-items: center;
  margin: 0;
}

.controls {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  z-index: 3;
}

.periodSelect {
  padding: 8px 12px;
  border: 2px solid #5ecfb6;
  border-radius: 6px;
  background-color: white;
  color: #2d8f7b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  position: relative;
  z-index: 3;
}

.periodSelect:hover {
  border-color: #2d8f7b;
}

.periodSelect:focus {
  border-color: #2d8f7b;
  box-shadow: 0 0 0 2px rgba(45, 143, 123, 0.1);
}

.chartContainer {
  position: relative;
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  isolation: isolate;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(94, 207, 182, 0.2);
  position: relative;
  z-index: 2;
}

.legendItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #2d8f7b;
  padding: 6px 12px;
  border-radius: 6px;
  transition: all 0.3s ease;
  background-color: white;
  border: 1px solid transparent;
  min-width: 150px;
  position: relative;
  z-index: 2;
}

.legendItem:hover {
  background-color: rgba(94, 207, 182, 0.1);
  border-color: rgba(94, 207, 182, 0.3);
  transform: translateY(-1px);
}

.legendDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.legendDot[style*="rotate"] {
  border-radius: 2px;
  transform-origin: center;
}

.textCenter {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #2d8f7b;
}

/* Tooltip styles */
.tooltipContent {
  background-color: white;
  border: 1px solid #5ecfb6;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  min-width: 250px;
  max-width: 320px;
  position: relative;
  z-index: 1000;
}

.tooltipHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #2d8f7b;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(94, 207, 182, 0.2);
}

.tooltipDate {
  font-size: 14px;
  color: #5ecfb6;
}

.tooltipBadge {
  background-color: #ff7f50;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  font-weight: 500;
}

.tooltipBody {
  font-size: 14px;
}

.tooltipDivider {
  margin: 8px 0;
  border: none;
  border-top: 1px dashed rgba(94, 207, 182, 0.2);
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.tooltipLabel {
  font-weight: 600;
  color: #2d8f7b;
  margin-right: 8px;
}

.tooltipValue {
  font-weight: 400;
  color: #333;
}

.totalScore {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(94, 207, 182, 0.3);
}

.totalScore .tooltipLabel,
.totalScore .tooltipValue {
  font-weight: 700;
  color: #2d8f7b;
}

/* Graph styles */
:global(.recharts-cartesian-grid-horizontal line),
:global(.recharts-cartesian-grid-vertical line) {
  stroke: rgba(94, 207, 182, 0.2);
}

:global(.recharts-tooltip-cursor) {
  stroke: #2d8f7b;
}

:global(.recharts-default-tooltip) {
  background-color: white !important;
  border: 1px solid #5ecfb6 !important;
}

:global(.recharts-tooltip-label) {
  color: #2d8f7b !important;
}

:global(.recharts-tooltip-item) {
  color: #5ecfb6 !important;
}

:global(.recharts-xAxis .recharts-cartesian-axis-tick-value),
:global(.recharts-yAxis .recharts-cartesian-axis-tick-value) {
  fill: #2d8f7b;
}

:global(.recharts-cartesian-axis-line) {
  stroke: #5ecfb6;
}

:global(.recharts-active-dot) {
  fill: #2d8f7b;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .title {
    text-align: center;
  }

  .controls {
    width: 100%;
    justify-content: center;
  }

  .periodSelect {
    width: 100%;
    max-width: 200px;
  }

  .legend {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .legendItem {
    width: 100%;
  }

  .tooltipContent {
    max-width: 280px;
  }
}