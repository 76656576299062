/* CharityDashboard.module.css */

.dashboardContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: auto;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 20px;
  margin-bottom: 20px;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 10px;
}

.highlight {
  color: var(--primary-color);
  font-weight: 500;
}

.searchInputWrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
}

.searchInput {
  width: 100%;
  padding: 10px 10px 10px 35px;
  border: 1px solid var(--border-light);
  border-radius: 20px;
  font-size: 14px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
}

.fileInput {
  margin-top: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.button {
  background: var(--primary-gradient);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: opacity 0.3s ease;
}

.button:hover {
  opacity: 0.9;
}

.iconButton {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  transition: color 0.3s ease;
}

.iconButton:hover {
  color: var(--primary-dark);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo {
    margin-bottom: 10px;
  }

  .grid {
    grid-template-columns: 1fr;
  }
}