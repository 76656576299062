/* BusinessCreateCampaign.module.css */

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--background-light);
}

.formContainer {
  width: 100%;
  max-width: 500px;
  padding: 48px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  position: relative;
  overflow: hidden;
}

.formContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--primary-gradient);
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 40px;
}

.form {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.formGroup {
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 15px;
}

.input,
.textarea {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 2px solid var(--border-light);
  border-radius: 12px;
  font-size: 15px;
  color: var(--text-primary);
  background-color: white;
  transition: var(--transition-standard);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.textarea {
  height: 120px;
  padding: 16px;
  resize: vertical;
}

.input:focus,
.textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(45, 143, 123, 0.1);
}

.error {
  padding: 14px 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fef2f2;
  border-left: 4px solid #dc2626;
  color: #dc2626;
}

.error svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.submitButton {
  width: 100%;
  height: 48px;
  margin-top: 16px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-standard);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.submitButton:hover:not(:disabled) {
  background: var(--primary-dark);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.submitButton:active:not(:disabled) {
  transform: translateY(0);
}

.submitButton:disabled {
  background: #a0a0a0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

.links {
  text-align: center;
  margin-top: 24px;
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: var(--transition-standard);
}

.link:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

@media (max-width: 480px) {
  .formContainer {
    padding: 32px 24px;
  }

  .title {
    font-size: 28px;
  }

  .input,
  .textarea,
  .submitButton {
    height: 46px;
    font-size: 14px;
  }
}