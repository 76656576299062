.carouselContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.carouselWrapper {
  margin: 0 40px;
}

.carouselItemWrapper {
  padding: 0 15px;
}

.carouselItem {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carouselItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.brandSection,
.charitySection,
.matchSection,
.causeSection {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.causeSection {
  border-bottom: none;
}

.icon {
  color: #2d8f7b;
  font-size: 20px;
  flex-shrink: 0;
}

.brandName {
  font-size: 22px;
  font-weight: 700;
  color: #236e61;
  margin: 0;
  line-height: 1.3;
}

.charityName {
  font-size: 16px;
  color: #424242;
  font-weight: 500;
}

.matchAmount {
  display: flex;
  align-items: center;
  gap: 8px;
}

.amount {
  font-size: 18px;
  font-weight: 600;
  color: #2d8f7b;
}

.multiplier {
  font-size: 14px;
  color: #666;
}

.causeDescription {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}

.validitySection {
  margin-top: auto;
  padding-top: 12px;
}

.validUntil {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.matchButton,
.learnMoreButton {
  display: inline-block;
  width: 100%;
  padding: 12px 20px;
  background-color: transparent;
  color: #2d8f7b;
  text-decoration: none;
  border: 2px solid #2d8f7b;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.matchButton:hover,
.learnMoreButton:hover {
  background-color: #2d8f7b;
  color: white;
}

.matchedButton {
  background-color: #666;
  border-color: #666;
  color: white;
  cursor: not-allowed;
}

.matchedButton:hover {
  background-color: #666;
  color: white;
}

.loadingState,
.errorState,
.emptyState {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 24px;
  color: #2d8f7b;
  margin-bottom: 16px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.retryButton {
  background: #2d8f7b;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 24px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
}

/* Custom arrow styles */
.slickArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}

.slickArrow::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-top: 2px solid #2d8f7b;
  border-right: 2px solid #2d8f7b;
}

.slickPrev {
  left: -40px;
}

.slickNext {
  right: -40px;
}

.slickPrev::before {
  transform: rotate(-135deg);
}

.slickNext::before {
  transform: rotate(45deg);
}

/* Override slick-carousel default styles */
:global(.slick-prev),
:global(.slick-next) {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

:global(.slick-dots) {
  bottom: -35px;
}

:global(.slick-dots li button:before) {
  font-size: 12px;
  color: #9E9E9E;
}

:global(.slick-dots li.slick-active button:before) {
  color: #2d8f7b;
}

:global(.slick-slide > div) {
  margin: 0 15px;
}

:global(.slick-list) {
  margin: 0 -15px;
}

:global(.slick-slide) {
  transform: scale(0.95);
  transition: transform 0.3s ease;
}

:global(.slick-center) {
  transform: scale(1);
}

:global(.slick-track) {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .carouselWrapper {
    margin: 0 20px;
  }

  .brandName {
    font-size: 18px;
  }

  .charityName {
    font-size: 14px;
  }

  .amount {
    font-size: 16px;
  }

  .multiplier {
    font-size: 12px;
  }

  .causeDescription {
    font-size: 12px;
  }

  .matchButton,
  .learnMoreButton {
    padding: 8px 20px;
    font-size: 14px;
  }
}