/* Login.module.css */
.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--background-light);
}

.loginContainer {
  width: 100%;
  max-width: 400px;
  padding: 48px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  position: relative;
  overflow: hidden;
}

.loginContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--primary-gradient);
}

.logo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 40px;
  object-fit: contain;
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 40px;
}

.loginForm {
  width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.formGroup {
  margin-bottom: 24px;
  width: 320px;
  box-sizing: border-box;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 15px;
}

.formGroup input,
.formGroup select {
  width: 320px;
  height: 48px;
  padding: 0 16px;
  border: 2px solid var(--border-light);
  border-radius: 12px;
  font-size: 15px;
  color: var(--text-primary);
  background-color: white;
  transition: var(--transition-standard);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.formGroup select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
  padding-right: 40px;
}

.formGroup input:focus,
.formGroup select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(45, 143, 123, 0.1);
}

.formGroup input.error,
.formGroup select.error {
  border-color: #dc2626;
}

.passwordWrapper {
  position: relative;
  width: 320px;
  box-sizing: border-box;
}

.toggleButton {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: var(--transition-standard);
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border-radius: 0 !important;
  min-height: unset !important;
  width: auto !important;
}

.toggleButton:hover {
  color: var(--primary-color);
}

.loginButton {
  width: 320px !important;
  height: 48px !important;
  margin-top: 16px !important;
  background: var(--primary-color) !important;
  color: white !important;
  border: none !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: var(--transition-standard) !important;
  box-sizing: border-box !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  padding: 0 !important;
  min-height: unset !important;
}

.loginButton:hover:not(:disabled) {
  background: var(--primary-dark) !important;
  transform: translateY(-1px) !important;
  box-shadow: var(--shadow-md) !important;
}

.loginButton:active:not(:disabled) {
  transform: translateY(0) !important;
}

.loginButton:disabled {
  background: #a0a0a0 !important;
  cursor: not-allowed !important;
  transform: none !important;
  box-shadow: none !important;
  opacity: 0.7;
}

.socialLogin {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid var(--border-light);
  text-align: center;
}

.socialLogin h3 {
  color: var(--text-secondary);
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.socialButtons {
  display: grid;
  gap: 12px;
  width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.loginSocialButton {
  width: 320px !important;
  height: 48px !important;
  border: 2px solid var(--border-light) !important;
  border-radius: 12px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  background: white !important;
  color: var(--text-primary) !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 12px !important;
  transition: var(--transition-standard) !important;
  box-sizing: border-box !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  padding: 0 16px !important;
  min-height: unset !important;
  position: relative;
}

.loginSocialButton:disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.loginSocialButton .socialIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.loginSocialButton span {
  flex-grow: 1;
  text-align: center;
}

.google:hover:not(:disabled) {
  border-color: #db4437 !important;
  background-color: rgba(219, 68, 55, 0.05) !important;
}

.microsoft:hover:not(:disabled) {
  border-color: #00a4ef !important;
  background-color: rgba(0, 164, 239, 0.05) !important;
}

.error,
.success {
  padding: 14px 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.error {
  background-color: #fef2f2;
  border-left: 4px solid #dc2626;
  color: #dc2626;
}

.success {
  background-color: #ecfdf5;
  border-left: 4px solid #059669;
  color: #059669;
}

.error svg,
.success svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.validationMessage {
  font-size: 13px;
  margin-top: 4px;
  display: block;
  width: 320px;
  box-sizing: border-box;
}

.validationMessage.error {
  color: #dc2626;
  background: none;
  border: none;
  padding: 0;
  margin: 4px 0 0;
}

.links {
  text-align: center;
  margin-top: 24px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.loginLink {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: var(--transition-standard);
}

.loginLink:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

@media (max-width: 480px) {
  .loginContainer {
    padding: 32px 24px;
  }

  .title {
    font-size: 28px;
  }

  .formGroup input,
  .formGroup select,
  .loginButton,
  .loginSocialButton {
    height: 46px;
    font-size: 14px;
  }

  .logo {
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
  }
}