/* Activity.module.css */
.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-light);
}

.activityHeader {
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.headerIcon {
  font-size: 1.8rem;
  color: var(--primary-color);
}

.activityHeader::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: var(--primary-gradient);
  margin: 0.5rem auto 0;
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border-light);
}

.sectionTitle h2 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin: 0;
  font-weight: 600;
}

.sectionIcon {
  font-size: 1.25rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.emailSection {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  position: relative;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: center;
}

.scrapeButton, .toggleButton, .clearButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}

.clearButton {
  background-color: #dc3545;
}

.clearButton:hover:not(:disabled) {
  background: linear-gradient(to right, #dc3545, #c82333);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(220, 53, 69, 0.2);
}

.clearButton:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: none;
}

.clearButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #6c757d;
}

.scrapeButton:hover:not(:disabled), .toggleButton:hover {
  background: var(--primary-gradient);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.scrapeButton:active:not(:disabled), .toggleButton:active {
  transform: translateY(0);
  box-shadow: none;
}

.scrapeButton:disabled {
  background-color: var(--text-secondary);
  cursor: not-allowed;
  transform: none;
  opacity: 0.7;
}

.searchHistory {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.searchHistory.clearing {
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
}

.searchEntry {
  border-top: 1px solid var(--border-light);
  padding-top: 1.5rem;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.searchEntry.clearing {
  opacity: 0;
  transform: translateY(-10px);
}

.searchEntry h5 {
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.emailResultsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;
}

.emailResultItem {
  background-color: white;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-sm);
  opacity: 1;
  transform: translateY(0);
}

.emailResultItem:hover {
  box-shadow: var(--shadow-md);
}

.emailResultItem.clearing {
  opacity: 0;
  transform: translateY(-10px);
}

.emailResultItem strong {
  color: var(--primary-color);
  display: inline-block;
  width: 80px;
  margin-right: 0.5rem;
  text-align: left;
}

.committedDonation {
  transform: scale(0.95);
  opacity: 0.85;
  border-left: 4px solid #4CAF50;
}

.deletedDonation {
  transform: scale(0.95);
  opacity: 0.75;
  border-left: 4px solid #f44336;
  background-color: #f8f8f8;
}

.donationHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-right: 2.5rem;
}

.donationHeader > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.donationHeader strong {
  width: 80px;
  flex-shrink: 0;
}

.donationContent {
  margin-left: 80px;
}

.statusBadge {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--background-light);
  margin-left: 1rem;
  white-space: nowrap;
}

.committedDonation .statusBadge {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.deletedDonation .statusBadge {
  background-color: #ffebee;
  color: #c62828;
}

.categorySelect {
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  background-color: white;
  color: var(--text-primary);
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.categorySelect:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(45, 143, 123, 0.1);
}

.actionButtons {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.linkButton, .restoreButton {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  flex: 1;
  text-align: center;
  text-decoration: none;
}

.linkButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.linkButton:hover {
  background: var(--primary-gradient);
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.restoreButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.restoreButton:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.saveButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 0.5rem;
  transition: all 0.3s ease;
}

.saveButton:hover:not(:disabled) {
  background: var(--primary-gradient);
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.saveButton:disabled {
  background-color: var(--text-secondary);
  cursor: not-allowed;
  opacity: 0.7;
}

.deleteButton {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
  transition: all 0.3s ease;
  z-index: 1;
}

.deleteButton:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}

.loading {
  text-align: center;
  color: var(--text-secondary);
  margin: 2rem 0;
}

.error {
  color: #dc3545;
  background-color: #ffe6e6;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
}

.authStatus {
  text-align: center;
  color: var(--text-secondary);
  margin: 1rem 0;
  font-style: italic;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .scrapeButton, .toggleButton, .clearButton {
    width: 100%;
  }

  .emailResultItem {
    padding: 1rem;
  }

  .actionButtons {
    flex-direction: column;
  }

  .emailResultItem strong {
    width: 70px;
  }

  .donationHeader {
    flex-direction: column;
    gap: 0.5rem;
  }

  .statusBadge {
    margin-left: 0;
  }

  .sectionTitle {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .sectionTitle h2 {
    font-size: 1.1rem;
  }

  .sectionIcon {
    font-size: 1.1rem;
  }

  /* In your Activity.module.css or SharedStyles.css */
.progressContainer {
  margin: 10px 0;
}

.progressBarBackground {
  width: 100%;
  height: 8px;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.progressBarFill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease;
}
}