.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 20px;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-secondary);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-secondary);
  margin-bottom: 5px;
}

.input,
.select {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 16px;
}

.input:focus,
.select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: var(--text-secondary);
}

.checkbox {
  width: 18px;
  height: 18px;
}

.fileInput {
  font-size: 14px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primaryButton {
  background-color: var(--primary-color);
  color: white;
}

.primaryButton:hover {
  background-color: var(--primary-dark);
}

.secondaryButton {
  background-color: var(--background-light);
  color: var(--text-primary);
}

.secondaryButton:hover {
  background-color: var(--background-dark);
}

@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    padding: 20px;
  }

  .modalTitle {
    font-size: 20px;
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
  }
}