.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.carouselWrapper {
  position: relative;
  padding: 0 40px;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
  background-color: rgba(45, 143, 123, 0.05);
  border-radius: 12px;
  margin: 2rem 0;
}

.error {
  text-align: center;
  padding: 2rem;
  background-color: #fff5f5;
  border-radius: 12px;
  margin: 2rem 0;
  border: 1px solid #dc3545;
}

.error p {
  color: #dc3545;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.retryButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border: 2px solid #dc3545;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  color: #dc3545;
  background-color: transparent;
  cursor: pointer;
  transition: var(--transition-standard);
}

.retryButton:hover {
  background-color: #dc3545;
  color: white;
}

.noProjects {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
  background-color: var(--background-light);
  border-radius: 12px;
  margin: 2rem 0;
}

.carouselItemWrapper {
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}

.carouselItem {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--border-light);
  max-height: 340px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.carouselItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.itemContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.itemTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0 0 12px 0;
  line-height: 1.3;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-light);
}

.projectSummary {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex: 1;
  min-height: 0;
}

.projectDetails {
  padding-top: 8px;
  border-top: 1px solid var(--border-light);
  margin-bottom: 12px;
}

.projectGoalLabel {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.projectGoal {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.1rem;
}

.buttonWrapper {
  margin-top: auto;
  width: 100%;
}

.learnMoreButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--primary-color);
  background-color: transparent;
  text-decoration: none;
  transition: var(--transition-standard);
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learnMoreButton:hover {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  color: white;
}

/* Carousel Navigation */
.slickArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}

.slickArrow::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-top: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
}

.slickPrev {
  left: -40px;
}

.slickNext {
  right: -40px;
}

.slickPrev::before {
  transform: rotate(-135deg);
}

.slickNext::before {
  transform: rotate(45deg);
}

/* Override slick-carousel default styles */
:global(.slick-prev),
:global(.slick-next) {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

:global(.slick-dots) {
  bottom: -35px;
}

:global(.slick-dots li button:before) {
  font-size: 8px;
  color: var(--border-light);
  opacity: 1;
}

:global(.slick-dots li.slick-active button:before) {
  color: var(--primary-color);
  opacity: 1;
}

:global(.slick-slide > div) {
  margin: 0 15px;
  height: 100%;
}

:global(.slick-list) {
  margin: 0 -15px;
  overflow: hidden;
}

:global(.slick-track) {
  display: flex;
  align-items: stretch;
  margin-left: 0;
  margin-right: 0;
}

:global(.slick-slide) {
  height: auto;
  opacity: 0.5;
  transition: all 0.3s ease;
}

:global(.slick-slide.slick-active) {
  opacity: 1;
}

:global(.slick-slide > div) {
  height: 100%;
}

/* Responsive Styles */
@media (max-width: 1280px) {
  .carouselWrapper {
    padding: 0 30px;
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 1rem;
  }

  .carouselItem {
    padding: 16px;
    max-height: 320px;
  }

  .itemTitle {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .projectGoal {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .carouselWrapper {
    padding: 0 25px;
  }

  .carouselItemWrapper {
    padding: 0 10px;
  }

  .carouselItem {
    padding: 14px;
    max-height: 300px;
  }

  .itemTitle {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .projectSummary {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .projectGoal {
    font-size: 0.95rem;
  }

  .learnMoreButton {
    padding: 6px 14px;
    font-size: 0.9rem;
  }
}