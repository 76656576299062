/* YourAccount.module.css */

.accountPage {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-light);
  min-height: 100vh;
  padding: 2rem;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

.pageTitle {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

.pageTitle::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: var(--primary-gradient);
  margin: 0.5rem auto 0;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-standard);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.cardHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.cardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.cardIcon svg {
  width: 100%;
  height: 100%;
  stroke: var(--primary-color);
  transition: var(--transition-standard);
}

.card:hover .cardIcon svg {
  stroke: var(--primary-light);
}

.card h2 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin: 0;
  line-height: 1.2;
  text-align: left;
}

.card p {
  color: var(--text-secondary);
  margin-bottom: 1rem;
  text-align: left;
}

.ctaTip {
  display: block;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  font-style: italic;
  text-align: left;
}

@media (max-width: 768px) {
  .accountPage {
    padding: 1rem;
  }

  .cardGrid {
    grid-template-columns: 1fr;
  }

  .cardIcon {
    width: 18px;
    height: 18px;
  }
}