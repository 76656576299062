.container {
  padding: 20px;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error {
  color: #dc3545;
  padding: 20px;
  background: #f8d7da;
  border-radius: 4px;
  margin-bottom: 20px;
}

.noRequests {
  text-align: center;
  color: #666;
  padding: 40px;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 20px 0;
}

.requestsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.requestCard {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.requestInfo h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.requestInfo p {
  margin: 5px 0;
  color: #666;
}

.evidence {
  margin-top: 10px;
}

.downloadButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.downloadButton:hover {
  background: #0056b3;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: auto;
}

.actionButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.approve {
  background: #28a745;
  color: white;
}

.approve:hover {
  background: #218838;
}

.reject {
  background: #dc3545;
  color: white;
}

.reject:hover {
  background: #c82333;
}

@media (max-width: 768px) {
  .requestsGrid {
    grid-template-columns: 1fr;
  }
}