:root {
  --primary-color: #2d8f7b;
  --primary-light: #5ecfb6;
  --primary-dark: #236e61;
  --primary-gradient: linear-gradient(to bottom, #5ecfb6, #2d8f7b);
  --hover-color: #236e61;
  --text-primary: #212121;
  --text-secondary: #757575;
  --background-light: #f8f8f8;
  --border-light: #e0e0e0;
  --shadow-sm: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 6px 12px rgba(0, 0, 0, 0.15);
  --transition-standard: all 0.3s ease;
}

.container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  padding: 2rem;
  margin-bottom: 2rem;
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header {
  font-size: 2.5rem;
  color: var(--primary-dark);
  margin: 0;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectionHeader {
  font-size: 1.8rem;
  color: var(--primary-dark);
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.paragraph strong {
  color: var(--text-primary);
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.primaryButton {
  background: var(--primary-gradient);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-standard);
}

.primaryButton:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.secondaryButton {
  background-color: white;
  color: var(--primary-color);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: 2px solid var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-standard);
}

.secondaryButton:hover {
  background: var(--background-light);
  transform: translateY(-2px);
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: var(--transition-standard);
}

.link:hover {
  color: var(--primary-light);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 4px solid var(--border-light);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.errorContainer {
  text-align: center;
  padding: 3rem;
}

.errorContainer h2 {
  color: #dc2626;
  margin-bottom: 1rem;
}

.beneficiariesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.beneficiaryTag {
  background-color: var(--background-light);
  color: var(--primary-color);
  padding: 0.75rem 1rem;
  border-radius: 50px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.beneficiaryTag:hover {
  background: var(--primary-gradient);
  color: white;
  transform: translateY(-2px);
  border-color: transparent;
}

.programsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 1.5rem;
}

.programCard {
  background-color: var(--background-light);
  border-radius: 12px;
  padding: 1.5rem;
  transition: var(--transition-standard);
  border: 1px solid var(--border-light);
}

.programCard:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
}

.programCard h3 {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.programCard p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.impactMetrics {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid var(--border-light);
}

.impactMetrics h4 {
  color: var(--primary-color);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.impactMetrics p {
  color: var(--text-secondary);
  margin-bottom: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .container {
    margin: 1rem;
    padding: 0;
  }

  .card {
    padding: 1.5rem;
  }

  .cardHeader {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .header {
    font-size: 2rem;
  }

  .beneficiariesGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .programsGrid {
    grid-template-columns: 1fr;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
  }
}