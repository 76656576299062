.container {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  margin: 16px auto;
  width: calc(100% - 32px);
  max-width: 1200px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header {
  font-size: 28px;
  font-weight: 500;
  color: #2d8f7b;
  margin: 0;
}

.sectionHeader {
  font-size: 22px;
  font-weight: 500;
  color: #2d8f7b;
  margin: 24px 0 12px;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(45, 143, 123, 0.1);
}

.campaignsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 4px;
  width: 100%;
  overflow-y: auto;
  max-height: 500px;
  scrollbar-width: thin;
  scrollbar-color: #2d8f7b rgba(45, 143, 123, 0.1);
}

.campaignsGrid::-webkit-scrollbar {
  width: 6px;
}

.campaignsGrid::-webkit-scrollbar-track {
  background: rgba(45, 143, 123, 0.1);
  border-radius: 3px;
}

.campaignsGrid::-webkit-scrollbar-thumb {
  background-color: #2d8f7b;
  border-radius: 3px;
}

.campaignsGrid:empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding-bottom: 16px;
}

.campaignCard {
  background-color: #ffffff;
  border: 1px solid rgba(45, 143, 123, 0.12);
  border-radius: 12px;
  padding: 20px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 250px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.archivedCard {
  composes: campaignCard;
  padding: 16px;
  background-color: #f8f9fa;
  border: 1px solid rgba(45, 143, 123, 0.08);
  min-height: 120px;
}

.archivedCard .cardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.archivedCard .cardTitle {
  font-size: 16px;
  margin-bottom: 8px;
  color: #2d8f7b;
  font-weight: 600;
  padding-right: 32px;
}

.archivedInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #2d8f7b;
  font-size: 13px;
  width: 100%;
}

.archivedInfo span {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1.4;
}

.archivedDate {
  color: #666;
  font-size: 13px;
}

.campaignCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
}

.cardTitle {
  font-size: 18px;
  font-weight: 500;
  color: #2d8f7b;
  padding-right: 32px;
  word-break: break-word;
  flex: 1;
  line-height: 1.3;
  margin: 0;
}

.cardContent {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5;
  color: #2d8f7b;
}

.cardContent p {
  margin: 12px 0;
  color: #2d8f7b;
  display: flex;
  align-items: center;
}

.cardContent strong {
  color: #2d8f7b;
  font-weight: 500;
  min-width: 100px;
  margin-right: 12px;
}

.raisedAmount {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
  color: #2d8f7b;
}

.raisedAmount strong {
  color: #2d8f7b;
  font-weight: 500;
  min-width: 100px;
  margin-right: 12px;
}

.editButton {
  background: none;
  border: none;
  color: #2d8f7b;
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  margin-left: 6px;
}

.editButton:hover {
  background-color: #5BA890;
  color: white;
  opacity: 1;
}

.iconButton {
  background: none;
  border: none;
  color: #2d8f7b;
  cursor: pointer;
  font-size: 13px;
  padding: 4px;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 0.5;
}

.iconButton:hover {
  background-color: #5BA890;
  color: white;
  opacity: 1;
}

.createButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  color: #2d8f7b;
  border: 2px solid #2d8f7b;
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  box-sizing: border-box;
}

.createButton:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
}

.error {
  color: #ff6b6b;
  margin-bottom: 12px;
  font-size: 13px;
}

.textCenter {
  text-align: center;
  color: #2d8f7b;
  font-size: 14px;
  padding: 24px;
  background-color: rgba(45, 143, 123, 0.02);
  border-radius: 12px;
  margin: 12px 0;
}

.progressBar {
  width: 100%;
  height: 3px;
  background-color: rgba(45, 143, 123, 0.08);
  border-radius: 2px;
  overflow: hidden;
  margin: 16px 0;
}

.progressFill {
  height: 100%;
  background: linear-gradient(to right, #5ecfb6, #2d8f7b);
  transition: width 0.3s ease;
}

.input {
  padding: 6px 10px;
  border: 1px solid rgba(45, 143, 123, 0.15);
  border-radius: 6px;
  font-size: 14px;
  color: #2d8f7b;
  transition: border-color 0.2s ease;
  width: 90px;
  margin: 0 6px;
}

.input:focus {
  outline: none;
  border-color: rgba(45, 143, 123, 0.4);
}

.tealButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #2d8f7b;
  border: 2px solid #2d8f7b;
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 16px;
  width: fit-content;
  box-sizing: border-box;
}

.tealButton:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
}

.campaignLink {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: #2d8f7b;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid #2d8f7b;
  transition: all 0.3s ease;
  margin-top: 16px;
  width: fit-content;
  box-sizing: border-box;
}

.campaignLink:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .container {
    padding: 12px;
    width: calc(100% - 24px);
    margin: 12px auto;
  }

  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .campaignsGrid {
    grid-template-columns: 1fr;
    max-height: 400px;
  }

  .header {
    font-size: 24px;
    margin-bottom: 0;
  }

  .sectionHeader {
    font-size: 18px;
    margin: 20px 0 10px;
  }

  .campaignCard {
    padding: 16px;
    min-height: 200px;
  }

  .archivedCard {
    padding: 14px;
    min-height: 120px;
  }

  .cardTitle {
    font-size: 16px;
    max-width: 100%;
  }

  .cardContent {
    font-size: 13px;
  }

  .createButton {
    font-size: 13px;
    padding: 10px 16px;
  }

  .campaignLink {
    font-size: 13px;
    padding: 10px 16px;
  }

  .archivedInfo {
    font-size: 13px;
  }

  .archivedCard .cardContent {
    gap: 6px;
  }
}