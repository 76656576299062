/* SearchCharities.module.css */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.searchSection {
  margin-bottom: 3rem;
  padding: 2.5rem;
  border-radius: 12px;
  background: linear-gradient(to right bottom, rgba(45, 143, 123, 0.05), rgba(91, 168, 144, 0.1));
  border: 1px solid var(--border-light);
}

.header {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.introText {
  font-size: 1.1rem;
  color: var(--text-secondary);
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.searchForm {
  max-width: 800px;
  margin: 0 auto;
}

.inputGroup {
  display: flex;
  width: 100%;
  position: relative;
  margin: 0 auto;
  background: white;
  border-radius: 50px;
  border: 2px solid var(--border-light);
  transition: all 0.3s ease;
  box-shadow: var(--shadow-sm);
}

.inputGroup:focus-within {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(45, 143, 123, 0.1);
}

.input {
  flex: 1;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 50px 0 0 50px;
  background-color: transparent;
  transition: all 0.3s ease;
  min-width: 0; /* Prevents flex item from overflowing */
}

.input:focus {
  outline: none;
}

.searchButton {
  min-width: 120px;
  padding: 0.75rem 1.25rem;
  font-size: 1.1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 0 50px 50px 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 4px;
  white-space: nowrap;
}

.searchButton:hover {
  background-color: var(--primary-dark);
  transform: translateY(0);
}

.searchButton svg {
  font-size: 1.2rem;
}

.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.card {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
  border: 1px solid var(--border-light);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.charityName {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
  line-height: 1.4;
}

.charityDetails {
  font-size: 0.95rem;
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.charityDetails strong {
  color: var(--text-primary);
  font-weight: 600;
  min-width: 4rem;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-light);
}

.viewDetailsButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 50px;
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.viewDetailsButton:hover {
  background-color: var(--primary-color);
  color: white;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
  font-size: 1.1rem;
  background-color: #fff5f5;
  border-radius: 12px;
  margin: 2rem 0;
}

.guidanceBox {
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 12px;
  background-color: var(--background-light);
  border-left: 4px solid var(--primary-color);
}

.guidanceBox h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.guidanceBox ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.guidanceBox li {
  margin-bottom: 0.75rem;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.guidanceBox li:last-child {
  margin-bottom: 0;
}

.guidanceBox li::before {
  content: "•";
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .header {
    font-size: 2rem;
  }

  .searchSection {
    padding: 1.5rem;
  }

  .inputGroup {
    flex-direction: row;
    border-radius: 50px;
  }

  .input {
    font-size: 1rem;
    padding: 0.875rem 1.25rem;
    border-radius: 50px 0 0 50px;
  }

  .searchButton {
    font-size: 1rem;
    padding: 0.75rem 1rem;
    margin: 3px;
    min-width: 100px;
  }

  .resultsGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}