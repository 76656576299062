.paymentSection {
  margin-bottom: 2rem;
}

.dropInContainer {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
}

.inputGroup {
  position: relative;
  display: flex;
  align-items: center;
}

.currencySymbol {
  position: absolute;
  left: 12px;
  color: var(--text-secondary);
  font-weight: 500;
}

.inputGroup input {
  padding-left: 25px !important;
}

.infoSection {
  background-color: var(--background-light);
  border-left: 4px solid var(--primary-color);
}

.list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.list li {
  padding: 0.5rem 0;
  color: var(--text-secondary);
  font-family: var(--font-mono);
  letter-spacing: 0.5px;
}

.note {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

/* PayPal Button Container */
.paypalContainer {
  margin: 1rem 0;
}

/* Loading and Error States */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: var(--text-secondary);
}

.message {
  text-align: center;
  color: var(--text-secondary);
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: var(--border-radius-md);
  margin: 1rem 0;
}

/* Form Layout */
.formLayout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* Payment Method Selection */
.paymentMethodSelect {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: var(--border-radius-md);
}

.paymentMethodSelect label {
  flex: 1;
  text-align: center;
  padding: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: var(--transition-standard);
}

.paymentMethodSelect label:hover {
  border-color: var(--primary-color);
}

.paymentMethodSelect input[type="radio"] {
  display: none;
}

.paymentMethodSelect input[type="radio"]:checked + label {
  border-color: var(--primary-color);
  background-color: var(--primary-light);
  color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .formLayout {
    grid-template-columns: 1fr;
  }
  
  .paymentMethodSelect {
    flex-direction: column;
  }
  
  .dropInContainer {
    margin: 1rem 0;
    padding: 0.5rem;
  }
}