.profileBackground {
  background-color: #f7fafc;
  min-height: 100vh;
  padding: 2rem 0;
}

.profileContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.impactSection {
  background: linear-gradient(to bottom, #ffffff, #f8fafc);
  border: 1px solid #e2e8f0;
}

.impactContent {
  margin-top: 2rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sectionHeader {
  margin-bottom: 1.5rem;
}

.sectionTitle {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.sectionIcon {
  margin-right: 0.75rem;
  font-size: 1.5rem;
  vertical-align: middle;
  color: var(--primary-color);
}

.gradientTitle {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectionTitleUnderline {
  height: 3px;
  width: 60px;
  background: var(--primary-gradient);
  border-radius: 2px;
}

.sectionSubtitle {
  color: #718096;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.donationsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.activitiesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.donationCard, .activityCard {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  min-height: 200px;
  box-sizing: border-box;
}

.cardTitle {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.icon {
  margin-right: 0.75rem;
  color: var(--primary-color);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  min-height: 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-radius: 6px;
  background: #f7fafc;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #4a5568;
}

.listItem:last-child {
  margin-bottom: 1rem;
}

.actionButton {
  composes: button from './SharedStyles.css';
  margin-top: auto;
  width: 100%;
}

.buttonIcon {
  margin-left: 0.5rem;
  transition: transform 0.2s;
}

.actionButton:hover .buttonIcon {
  transform: translateX(4px);
  color: white;
}

.expandedContent {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.deleteButton {
  composes: iconButton from './SharedStyles.css';
  color: #e53e3e;
  margin-left: 0.5rem;
}

.deleteButton:hover {
  background: #fed7d7;
  color: #e53e3e;
}

.followNewButton {
  composes: button from './SharedStyles.css';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 50px;
}

.fullWidth {
  width: 100%;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #4a5568;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #e53e3e;
}

.retryButton {
  composes: button from './SharedStyles.css';
  margin-top: 1rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .profileContainer {
    padding: 0 0.5rem;
  }

  .section {
    padding: 1.5rem;
  }

  .sectionTitle {
    font-size: 1.5rem;
  }

  .donationsGrid, .activitiesGrid {
    grid-template-columns: 1fr;
  }

  .cardTitle {
    font-size: 1.1rem;
  }

  .listItem {
    font-size: 0.9rem;
  }

  .donationCard {
    padding: 1.25rem;
  }

  .impactContent {
    padding: 0.75rem;
  }

  .actionButton {
    padding: 8px 20px;
    font-size: 14px;
  }
}