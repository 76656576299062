.breakdownContainer {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #2d8f7b;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #5ecfb6;
}

.breakdownTitle {
  font-size: 24px;
  font-weight: bold;
  color: #2d8f7b;
  margin-bottom: 20px;
}

.breakdownItem {
  margin-bottom: 20px;
}

.breakdownLabel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2d8f7b;
}

.breakdownProgressBar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.breakdownProgress {
  height: 100%;
  background: linear-gradient(to right, #5ecfb6, #2d8f7b);
  transition: width 0.3s ease-in-out;
}

.explanationText {
  margin-top: 30px;
}

.explanationText h3 {
  font-size: 20px;
  color: #2d8f7b;
  margin-bottom: 15px;
}

.explanationText h4 {
  font-size: 18px;
  color: #5ecfb6;
  margin-top: 20px;
  margin-bottom: 10px;
}

.explanationText p, .explanationText ul {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.explanationText ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.explanationText li {
  margin-bottom: 5px;
}

/* Add a subtle hover effect to list items */
.explanationText li:hover {
  color: #2d8f7b;
  transition: color 0.3s ease;
}