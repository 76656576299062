.scrollableImpactSection {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Swiper styles */
:global(.swiper-container) {
  width: 100%;
  height: 400px; /* Set a fixed height to match ImpactVisualization */
}

:global(.swiper-slide) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.swiper-button-next),
:global(.swiper-button-prev) {
  display: none; /* Hide the chevron navigation */
}

:global(.swiper-pagination) {
  display: none; /* Hide the default pagination */
}

/* Ensure content inside slides is visible */
:global(.swiper-slide > div) {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

/* Common styles for all components */
.impactVisualization,
.impactScoreExplain,
.tierProgressModal,
.badgesGrid {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #333;
}

/* Remove headings from individual components */
.impactVisualization h2,
.impactScoreExplain h2,
.tierProgressModal h2 {
  display: none;
}

/* Badges Display Styles */
.badgesGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns of badges */
  gap: 15px;
  padding: 15px;
}

.badgeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  transition: all 0.3s ease;
}

.badgeItem.collected {
  background-color: #e8f5e9;
}

.badgeItem:hover {
  transform: translateY(-3px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.badgeTitle {
  margin-top: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

/* Navigation styles for the key legend */
.impactSectionNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  border-radius: 30px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.impactSectionNavButton {
  background-color: transparent;
  border: none;
  color: #333;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
}

.impactSectionNavButton:hover {
  background-color: rgba(94, 207, 182, 0.1);
}

.impactSectionNavButton.active {
  background: linear-gradient(to bottom, #5ecfb6, #2d8f7b);
  color: white;
}

.arrowNavigation {
  display: flex;
  align-items: center;
}

.arrowButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.chevronIcon {
  font-size: 20px;
  color: #2d8f7b;
}

.arrowButton:hover .chevronIcon {
  color: #5ecfb6;
}