/* SharedStyles.css */
:root {
  --primary-color: #2d8f7b;
  --primary-light: #5BA890;
  --primary-dark: #236e61;
  --primary-gradient: linear-gradient(90deg, var(--primary-color), var(--primary-light));
  --hover-color: #5BA890;
  --text-primary: #212121;
  --text-secondary: #757575;
  --background-light: #f8f8f8;
  --border-light: #e0e0e0;
  --shadow-sm: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 6px 12px rgba(0, 0, 0, 0.15);
  --transition-standard: all 0.3s ease;
}

/* Button Styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: var(--transition-standard);
  text-decoration: none;
  background-color: transparent;
  color: var(--primary-color);
  min-height: 44px;
  gap: 8px;
  box-sizing: border-box;
}

.button:hover {
  background-color: var(--hover-color);
  color: white;
  border-color: var(--hover-color);
}

.button.compact {
  padding: 8px 16px;
  font-size: 14px;
  min-height: 36px;
}

/* Icon Button */
.iconButton {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  transition: var(--transition-standard);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton:hover {
  background-color: var(--hover-color);
  color: white;
}

/* Typography */
.title {
  font-size: 22px;
  font-weight: 700;
  color: var(--primary-dark);
  margin-bottom: 15px;
  line-height: 1.3;
}

.description {
  font-size: 16px;
  color: var(--text-primary);
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Data Display Labels */
.charityLabel,
.contributionLabel,
.multiplierLabel,
.validUntilLabel {
  font-size: 14px;
  color: var(--text-secondary);
  font-style: italic;
  margin-bottom: 5px;
}

/* Data Display Values */
.charityName,
.contributionAmount,
.multiplierValue,
.validUntilDate {
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 600;
  margin-bottom: 10px;
}

.contributionAmount {
  color: var(--primary-color);
}

.highlight {
  color: var(--primary-color);
  font-weight: 500;
}

.gradientTitle {
  font-weight: bold;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Link Styles */
.link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 16px;
}

.link:hover {
  color: var(--primary-light);
}

/* Card Styles */
.card {
  background-color: white;
  border: 1px solid var(--border-light);
  border-radius: 12px;
  padding: 25px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-standard);
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-sizing: border-box;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6px;
}

.cardTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-color);
  padding-right: 30px;
  word-break: break-word;
  flex: 1;
  line-height: 1.2;
  margin: 0;
}

.cardContent {
  font-size: 13px;
  color: var(--primary-color);
  flex-grow: 1;
  line-height: 1.2;
}

.cardContent p {
  margin: 4px 0;
  color: var(--primary-color);
}

.cardContent strong {
  color: var(--primary-color);
  font-weight: 600;
  margin-right: 4px;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  gap: 6px;
}

/* Container Styles */
.container {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: var(--shadow-sm);
  max-width: 1200px;
  margin: 0 auto;
}

.validationButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

/* Layout Utilities */
.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
}

/* Spacing */
.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.textCenter {
  text-align: center;
  color: var(--primary-color);
}

/* Responsive */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .button {
    padding: 10px 16px;
    font-size: 14px;
    min-height: 40px;
  }

  .card {
    padding: 20px;
  }

  .cardTitle {
    font-size: 13px;
  }

  .cardContent {
    font-size: 12px;
  }
}