/* Perks Container - Improved Layout */
.perksContainer {
  width: 100%;
  padding: 32px;
  background-color: var(--background-light, #f9fafb);
  border-radius: 16px;
  margin-top: 20px;
}

.header {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
}

.header::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 80px;
  height: 4px;
  background: var(--primary-gradient);
  border-radius: 2px;
}

.intro {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: 40px;
  max-width: 800px;
}

.impactScoreContainer {
  margin-bottom: 50px;
}

/* Grid Layout for Perks */
.perksList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 24px;
  margin-bottom: 40px;
}

.perkCard {
  background-color: white;
  border-radius: 16px;
  box-shadow: var(--shadow-sm, 0 4px 12px rgba(0, 0, 0, 0.05));
  padding: 28px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.perkCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md, 0 10px 20px rgba(0, 0, 0, 0.08));
}

/* Perk Header Styling */
.perkHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.perkIcon {
  font-size: 1.8rem;
  margin-right: 18px;
  color: var(--primary-color);
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--primary-color-rgb, 49, 151, 149), 0.1);
  border-radius: 12px;
  flex-shrink: 0;
}

/* Badge for new or featured items */
.badge {
  display: inline-block;
  padding: 4px 10px;
  background: linear-gradient(135deg, #38B2AC 0%, #2C7A7B 100%);
  color: white;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.new {
  background: linear-gradient(135deg, #38B2AC 0%, #2C7A7B 100%);
}

.featured {
  background: linear-gradient(135deg, #ED8936 0%, #DD6B20 100%);
}

.limited {
  background: linear-gradient(135deg, #9F7AEA 0%, #805AD5 100%);
}

/* Always visible perk details */
.perkDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

/* Event Cards */
.eventList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
}

.eventCard {
  background-color: var(--background-light, #f9fafb);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.eventCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.eventIconContainer {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-gradient);
  border-radius: 12px;
  margin-right: 16px;
  flex-shrink: 0;
}

.eventIcon {
  font-size: 1.8rem;
  color: white;
}

.eventInfo {
  flex-grow: 1;
}

/* Feature List */
.featureList {
  list-style-type: none;
  padding: 0;
}

.featureItem {
  padding: 14px;
  margin-bottom: 12px;
  background-color: var(--background-light, #f9fafb);
  border-radius: 10px;
  transition: transform 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.featureItem:hover {
  transform: translateX(5px);
}

/* Offers Styling */
.offerList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.offerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 12px;
  background-color: var(--background-light, #f9fafb);
  border-radius: 10px;
  transition: transform 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.offerItem:hover {
  transform: translateX(5px);
}

.offerItem:last-child {
  margin-bottom: 0;
}

.offerInfo {
  display: flex;
  flex-direction: column;
}

.offerTitle {
  font-weight: 600;
  font-size: 1.05rem;
  margin-bottom: 4px;
  color: var(--text-primary);
}

.offerDescription {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.redeemButton {
  background: var(--primary-gradient);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-left: 12px;
  flex-shrink: 0;
}

.redeemButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(var(--primary-color-rgb, 49, 151, 149), 0.3);
}

/* Referral Program */
.referralProgram {
  background: linear-gradient(135deg, var(--background-light, #f9fafb) 0%, white 100%);
  border-radius: 16px;
  padding: 30px;
  margin-top: 50px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(var(--primary-color-rgb, 49, 151, 149), 0.1);
}

.referralHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.referralIcon {
  font-size: 1.8rem;
  margin-right: 12px;
  color: var(--primary-color);
}

.referralText {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin-bottom: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.referralButton {
  background: var(--primary-gradient);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 15px;
  min-width: 200px;
}

.referralButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(var(--primary-color-rgb, 49, 151, 149), 0.4);
}

/* Call to Action */
.callToAction {
  margin-top: 60px;
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.ctaText {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin-bottom: 25px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.ctaButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
}

.ctaButton {
  padding: 14px 28px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 180px;
}

.ctaButton.primary {
  background: var(--primary-gradient);
  color: white;
}

.ctaButton.secondary {
  background: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.ctaButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .perksContainer {
    padding: 24px 20px;
  }
  
  .header {
    font-size: 2.2rem;
  }
  
  .intro {
    font-size: 1.1rem;
  }
  
  .perksList {
    grid-template-columns: 1fr;
  }
  
  .eventList {
    grid-template-columns: 1fr;
  }
  
  .offerItem {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .redeemButton {
    margin-left: 0;
    margin-top: 12px;
    align-self: flex-start;
  }
  
  .ctaButtons {
    flex-direction: column;
    align-items: center;
  }
  
  .ctaButton {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .perksContainer {
    padding: 20px 16px;
  }
  
  .header {
    font-size: 2rem;
  }
  
  .perkHeader h2 {
    font-size: 1.3rem;
  }
  
  .eventIconContainer {
    width: 50px;
    height: 50px;
  }
  
  .eventIcon {
    font-size: 1.5rem;
  }
}