.container {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  margin: 16px auto;
  width: calc(100% - 32px);
  max-width: 800px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header {
  font-size: 28px;
  font-weight: 500;
  color: #2d8f7b;
  margin: 0;
}

.activitiesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  padding: 4px;
  width: 100%;
  overflow-y: auto;
  max-height: 500px;
  scrollbar-width: thin;
  scrollbar-color: #2d8f7b rgba(45, 143, 123, 0.1);
}

.activitiesGrid::-webkit-scrollbar {
  width: 6px;
}

.activitiesGrid::-webkit-scrollbar-track {
  background: rgba(45, 143, 123, 0.1);
  border-radius: 3px;
}

.activitiesGrid::-webkit-scrollbar-thumb {
  background-color: #2d8f7b;
  border-radius: 3px;
}

.activityCard {
  background-color: #ffffff;
  border: 1px solid rgba(45, 143, 123, 0.12);
  border-radius: 12px;
  padding: 14px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0;
}

.activityCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.cardTitle {
  font-size: 15px;
  font-weight: 500;
  color: #2d8f7b;
  padding-right: 32px;
  word-break: break-word;
  flex: 1;
  line-height: 1.3;
  margin: 0;
}

.cardContent {
  flex-grow: 1;
  font-size: 13px;
  line-height: 1.5;
  color: #2d8f7b;
}

.cardContent p {
  margin: 6px 0;
  color: #2d8f7b;
  display: flex;
  align-items: center;
}

.cardContent strong {
  color: #2d8f7b;
  font-weight: 500;
  min-width: 70px;
  margin-right: 12px;
}

.iconButton {
  background: none;
  border: none;
  color: #2d8f7b;
  cursor: pointer;
  font-size: 12px;
  padding: 4px;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0.5;
}

.iconButton:hover {
  background-color: #5BA890;
  color: white;
  opacity: 1;
}

.createButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  color: #2d8f7b;
  border: 2px solid #2d8f7b;
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.createButton:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
}

.error {
  color: #ff6b6b;
  margin-bottom: 12px;
  font-size: 13px;
}

.textCenter {
  text-align: center;
  color: #2d8f7b;
  font-size: 14px;
  padding: 24px;
}

.status {
  font-size: 11px;
  font-weight: 400;
  padding: 2px 8px;
  border-radius: 10px;
  position: absolute;
  top: 4px;
  right: 28px;
}

.status.active {
  background-color: rgba(45, 143, 123, 0.04);
  color: #2d8f7b;
}

.status.pending {
  background-color: rgba(255, 165, 0, 0.04);
  color: #ffa500;
}

.fileUploadContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
}

.fileInput {
  display: none;
}

.fileUploadButton {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: transparent;
  color: #2d8f7b;
  border: 2px solid #2d8f7b;
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fileUploadButton:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
}

.fileName {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #2d8f7b;
  font-size: 13px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.evidenceLink {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: #2d8f7b;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid #2d8f7b;
  transition: all 0.3s ease;
  margin-top: 12px;
  width: fit-content;
}

.evidenceLink:hover {
  background-color: #5BA890;
  border-color: #5BA890;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .container {
    padding: 12px;
    width: calc(100% - 24px);
    margin: 12px auto;
  }

  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .header {
    font-size: 24px;
  }

  .activitiesGrid {
    grid-template-columns: 1fr;
    max-height: 400px;
  }

  .activityCard {
    padding: 12px;
  }

  .cardTitle {
    font-size: 14px;
  }

  .cardContent {
    font-size: 12px;
  }

  .createButton {
    font-size: 13px;
    padding: 10px 16px;
  }

  .fileUploadButton,
  .evidenceLink {
    font-size: 13px;
    padding: 10px 16px;
  }

  .fileName {
    font-size: 12px;
    max-width: 140px;
  }
}