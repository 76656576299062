.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
}

.modalContent {
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  z-index: 10000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.closeButton:hover {
  color: #333;
  background-color: #f5f5f5;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formGroup label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.formGroup input[type="text"],
.formGroup input[type="number"],
.formGroup input[type="date"],
.formGroup input[type="url"],
.formGroup select,
.formGroup textarea {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  background-color: white;
}

.formGroup input[type="text"]:focus,
.formGroup input[type="number"]:focus,
.formGroup input[type="date"]:focus,
.formGroup input[type="url"]:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(45, 143, 123, 0.1);
}

.formGroup input[type="url"] {
  font-family: monospace;
  letter-spacing: 0.5px;
}

.formGroup input[type="url"]::placeholder {
  color: #aaa;
  letter-spacing: normal;
}

.formGroup textarea {
  min-height: 100px;
  resize: vertical;
}

.formGroup input[type="file"] {
  padding: 8px;
  border: 1px dashed #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.formGroup input[type="file"]:hover {
  border-color: var(--primary-color);
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.confirmButton {
  background-color: var(--primary-color);
  color: white;
}

.confirmButton:hover:not(:disabled) {
  background-color: var(--primary-dark);
}

.cancelButton {
  background-color: #f0f0f0;
  color: #666;
}

.cancelButton:hover {
  background-color: #e0e0e0;
  color: #333;
}

.fieldError {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.modalHeader {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0 0 20px;
  padding-right: 40px;
}

@media (max-width: 600px) {
  .modalContent {
    width: 95%;
    padding: 20px;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 10px;
  }

  .button {
    width: 100%;
  }

  .formGroup input[type="text"],
  .formGroup input[type="number"],
  .formGroup input[type="date"],
  .formGroup input[type="url"],
  .formGroup select,
  .formGroup textarea {
    font-size: 16px; /* Prevent zoom on mobile */
  }
}